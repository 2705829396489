// PostDetails.js
import React, { useState, useRef, useEffect } from "react";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { ScrollArea } from "./ui/scroll-area";
import { AlertCircle } from "lucide-react";
import { Badge } from "./ui/badge";
import { useToast } from "./ui/use-toast";
import { Toaster } from "./ui/toaster";
import { Alert, AlertTitle, AlertDescription } from "./ui/alert";
import FormattedPost from "./FormattedPost"; // Add this import

import {
  Loader2,
  ExternalLink,
  Plus,
  RefreshCw,
  FileIcon,
  Link,
  MessageSquare,
} from "lucide-react";

const PostDetails = ({
  selectedSubmission,
  contentIdeas,
  selectedIdeas,
  generatedPosts,
  isGeneratingIdeas,
  isScrapingPosts,
  isGeneratingPosts,
  handleIdeaSelection,
  generateContentIdeas,
  scrapeLinkedInPosts,
  generateFullPosts,
  createTrelloCard,
  supabase,
  onUpdate,
  contentIdeasRef,
  selectedIdeasRef,
  generatedPostsRef,
}) => {
  const [linkedInPosts, setLinkedInPosts] = useState(Array(5).fill(""));

  useEffect(() => {
    // Initialize posts array with 5 empty strings
    const posts = Array(5).fill("");

    // Fill in existing posts
    [
      selectedSubmission.last_post_1,
      selectedSubmission.last_post_2,
      selectedSubmission.last_post_3,
      selectedSubmission.last_post_4,
      selectedSubmission.last_post_5,
    ].forEach((post, index) => {
      if (post) {
        try {
          posts[index] = JSON.parse(post).text;
        } catch (e) {
          console.error("Error parsing post:", e);
          posts[index] = "";
        }
      }
    });

    setLinkedInPosts(posts);
  }, [selectedSubmission]);

  const { toast } = useToast();

  // Modify the handleScrapeLinkedInPosts function to handle errors
  const handleScrapeLinkedInPosts = async () => {
    setScrapingError(null); // Clear any previous errors
    try {
      const scrapedPosts = await scrapeLinkedInPosts(selectedSubmission);
      setLinkedInPosts(scrapedPosts);
    } catch (error) {
      console.error("Error scraping posts:", error);
      // Extract the nested error message
      const errorMessage =
        error.response?.data?.error?.message ||
        error.response?.data?.message ||
        error.message ||
        "Failed to scrape LinkedIn posts";
      setScrapingError(errorMessage);
    }
  };

  const interviewAnswers = selectedSubmission.interview_answers || [];

  const tryParseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return { text: jsonString };
    }
  };

  const [posts, setPosts] = useState({
    last_post_1: tryParseJSON(selectedSubmission.last_post_1) || "",
    last_post_2: tryParseJSON(selectedSubmission.last_post_2) || "",
    last_post_3: tryParseJSON(selectedSubmission.last_post_3) || "",
    last_post_4: tryParseJSON(selectedSubmission.last_post_4) || "",
    last_post_5: tryParseJSON(selectedSubmission.last_post_5) || "",
  });
  const [isUpdatingPosts, setIsUpdatingPosts] = useState(false);
  const [error, setError] = useState(null);

  const [scrapingError, setScrapingError] = useState(null);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handlePostChange = (index, value) => {
    setLinkedInPosts((prev) => {
      const newPosts = [...prev];
      newPosts[index] = value;
      return newPosts;
    });
  };

  const handleUpdatePosts = async () => {
    const updateObject = {};
    linkedInPosts.forEach((post, index) => {
      updateObject[`last_post_${index + 1}`] = JSON.stringify({ text: post });
    });

    try {
      const { data, error } = await supabase
        .from("form_submissions")
        .update(updateObject)
        .eq("id", selectedSubmission.id);

      if (error) throw error;

      onUpdate(updateObject);
      toast({
        title: "Success",
        description: "Posts updated successfully!",
        duration: 3000,
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update posts. Please try again.",
        duration: 3000,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <Toaster />

        <CardHeader>
          <CardTitle>Basic Information</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="font-semibold">Website:</p>
              <a
                href={selectedSubmission.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline flex items-center"
              >
                <Link className="w-4 h-4 mr-1" />
                {selectedSubmission.website}
              </a>
            </div>
            <div>
              <p className="font-semibold">Email:</p>
              <p>{selectedSubmission.email}</p>
            </div>
            <div>
              <p className="font-semibold">Industry:</p>
              <p>{selectedSubmission.industry}</p>
            </div>
            <div>
              <p className="font-semibold">USP + Product:</p>
              <p>{selectedSubmission.usp}</p>
            </div>
            <div>
              <p className="font-semibold">ICP + Personas:</p>
              <p>{selectedSubmission.icp}</p>
            </div>
            <div>
              <p className="font-semibold">LinkedIn Profile:</p>
              <a
                href={selectedSubmission.linkedin_profile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline flex items-center"
              >
                <ExternalLink className="w-4 h-4 mr-1" />
                View Profile
              </a>
            </div>
            <div>
              <p className="font-semibold">Topics to Talk About:</p>
              <p>{selectedSubmission.topics_to_talk_about}</p>
            </div>
            <div>
              <p className="font-semibold">Submitted At:</p>
              <p>{new Date(selectedSubmission.created_at).toLocaleString()}</p>
            </div>
            {selectedSubmission.trello_card_id && (
              <div>
                <p className="font-semibold">Trello Card:</p>
                <a
                  href={`https://trello.com/c/${selectedSubmission.trello_card_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline flex items-center"
                >
                  <ExternalLink className="w-4 h-4 mr-1" />
                  Open Trello Card
                </a>
              </div>
            )}
            {selectedSubmission.brand_manual_url && (
              <div>
                <p className="font-semibold">Brand Manual:</p>
                <a
                  href={selectedSubmission.brand_manual_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline flex items-center"
                >
                  <FileIcon className="w-4 h-4 mr-1" />
                  View Brand Manual
                </a>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center">
            <MessageSquare className="w-5 h-5 mr-2" />
            Interview Answers
          </CardTitle>
        </CardHeader>
        <CardContent>
          {interviewAnswers.length > 0 ? (
            <ul className="list-disc list-inside space-y-2">
              {interviewAnswers.map((answer, index) => (
                <li key={index} className="text-gray-700">
                  {answer}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500 italic">
              No interview answers available.
            </p>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            LinkedIn Posts
            <div className="flex space-x-2">
              <Button
                onClick={handleScrapeLinkedInPosts}
                disabled={isScrapingPosts}
                variant="outline"
                className="bg-blue-500 hover:bg-blue-600 text-white"
              >
                {isScrapingPosts ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Scraping Posts...
                  </>
                ) : (
                  <>
                    <RefreshCw className="mr-2 h-4 w-4" />
                    Scrape Posts
                  </>
                )}
              </Button>
              <Button
                onClick={handleUpdatePosts}
                disabled={isUpdatingPosts}
                variant="outline"
                className="bg-green-500 hover:bg-green-600 text-white"
              >
                {isUpdatingPosts ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Updating...
                  </>
                ) : (
                  "Update Posts"
                )}
              </Button>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {/* Create array of 5 elements and map over them */}
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} className="space-y-2">
                <label
                  htmlFor={`post-${index + 1}`}
                  className="block text-sm font-medium text-gray-700"
                >
                  Post {index + 1}
                </label>
                <Textarea
                  id={`post-${index + 1}`}
                  value={linkedInPosts[index] || ""} // Use empty string if no post exists
                  onChange={(e) => handlePostChange(index, e.target.value)}
                  placeholder={`Enter content for LinkedIn post ${index + 1}`}
                  rows={4}
                  className="w-full border-2 border-gray-200 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                />
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {scrapingError && (
        <Alert variant="destructive" className="mb-4 border-2 border-red-500">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle className="font-bold">Failed to Scrape Posts</AlertTitle>
          <AlertDescription className="mt-1">{scrapingError}</AlertDescription>
        </Alert>
      )}

      <div className="flex space-x-4">
        <Button
          onClick={() => generateContentIdeas(selectedSubmission)}
          disabled={isGeneratingIdeas}
          className="bg-green-500 hover:bg-green-600 text-white"
        >
          {isGeneratingIdeas ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Generating Ideas...
            </>
          ) : (
            <>
              <Plus className="mr-2 h-4 w-4" />
              Generate Ideas
            </>
          )}
        </Button>
      </div>

      {contentIdeas.length > 0 && (
        <Card ref={contentIdeasRef}>
          <CardHeader>
            <CardTitle>Generated Content Ideas</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[400px]">
              {contentIdeas.map((idea) => (
                <div
                  key={idea.id}
                  className="mb-4 p-4 border rounded hover:bg-gray-50"
                >
                  <h4 className="font-bold">{idea.title}</h4>
                  <p className="text-sm text-gray-600">{idea.description}</p>
                  <Badge className="mt-2">{idea.contentType}</Badge>
                  <p className="mt-2 text-sm italic">"{idea.hook}"</p>
                  <Button
                    onClick={() => handleIdeaSelection(idea)}
                    variant="outline"
                    className="mt-2"
                  >
                    {selectedIdeas.includes(idea) ? "Deselect" : "Select"}
                  </Button>
                </div>
              ))}
            </ScrollArea>
          </CardContent>
        </Card>
      )}

      {selectedIdeas.length > 0 && (
        <Card ref={selectedIdeasRef}>
          <CardHeader>
            <CardTitle>Selected Ideas</CardTitle>
          </CardHeader>
          <CardContent>
            {selectedIdeas.map((idea) => (
              <div key={idea.id} className="mb-2">
                <p className="font-semibold">{idea.title}</p>
              </div>
            ))}
            <Button
              onClick={generateFullPosts}
              disabled={isGeneratingPosts}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white"
            >
              {isGeneratingPosts ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Generating Posts...
                </>
              ) : (
                "Generate Full Posts"
              )}
            </Button>
          </CardContent>
        </Card>
      )}

      {generatedPosts.length > 0 && (
        <Card ref={generatedPostsRef}>
          <CardHeader>
            <CardTitle>Generated Posts</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[600px]">
              {" "}
              {/* Increased height for better visibility */}
              <div className="space-y-6">
                {generatedPosts.map((post, index) => (
                  <FormattedPost key={index} post={post} />
                ))}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default PostDetails;
