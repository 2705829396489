import React, { useState, useRef, useEffect } from "react";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { Mic, ArrowRight, Check, Phone, PhoneOff } from "lucide-react";
import { useToast } from "./ui/use-toast";
import QuestionStatus from "./QuestionStatus";

const LightningBoltEmoji = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 4L8 28H22L18 44L40 20H26L30 4H24Z"
      fill="#FF5722"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M26 4L22 28H36L14 44L18 28H4L20 4H26Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);

const SpeechToTextApp = ({
  onAnswerRecorded,
  onComplete,
  questions,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  answers = {},
  hasStartedInterview = false, // New prop to track interview state
  setHasStartedInterview, // New prop to update interview state
}) => {
  const [isCallActive, setIsCallActive] = useState(hasStartedInterview);
  const [isRecording, setIsRecording] = useState(false);
  const [hasRecorded, setHasRecorded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const { toast } = useToast();

  useEffect(() => {
    setHasRecorded(Boolean(answers[currentQuestionIndex]));
  }, [currentQuestionIndex, answers]);

  const isCurrentQuestionAnswered = Boolean(answers[currentQuestionIndex]);

  const startCall = () => {
    setIsCallActive(true);
    setHasStartedInterview(true); // Update the parent's state
    toast({
      title: "Call Started",
      description: "You're now connected. Let's begin the interview!",
    });
  };

  const endCall = () => {
    setIsCallActive(false);
    setIsRecording(false);
    setHasRecorded(false);
    setCurrentQuestionIndex(0);
    toast({
      title: "Call Ended",
      description: "Thank you for completing the interview.",
    });
    onComplete();
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        sendAudioToAPI(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setHasRecorded(false);
      toast({
        title: "Recording Started",
        description: "Speak clearly into your microphone.",
      });
    } catch (error) {
      console.error("Error accessing microphone:", error);
      toast({
        title: "Error",
        description:
          "Unable to access microphone. Please check your permissions.",
        variant: "destructive",
      });
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      toast({
        title: "Recording Stopped",
        description: "Processing your answer...",
      });
    }
  };

  const sendAudioToAPI = async (audioBlob) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.wav");

    try {
      const response = await fetch("/api/transcribe", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setIsLoading(false);
      setHasRecorded(true);

      if (result && result.text) {
        onAnswerRecorded(currentQuestionIndex, result.text);
      } else {
        throw new Error("Failed to get transcription");
      }
    } catch (error) {
      console.error("Error transcribing audio:", error);
      toast({
        title: "Transcription Error",
        description: `Failed to transcribe audio: ${error.message}. Please try again.`,
        variant: "destructive",
      });
      setIsLoading(false);
    }
  };

  const confirmAnswer = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setHasRecorded(false);
      toast({
        title: "Answer Confirmed",
        description: "Moving to the next question.",
      });
    } else {
      endCall();
    }
  };

  return (
    <div className="container mx-auto flex items-center justify-center">
      <Card className="w-full max-w-md bg-white border-4 rounded-2xl border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
        <CardContent className="p-6">
          <div className="text-center mb-6">
            <h1 className="text-4xl font-bold mb-2 text-black">
              Virtual Interview
            </h1>
            {isCallActive && (
              <>
                <div className="bg-black text-white p-2 mb-4">
                  Question {currentQuestionIndex + 1} of {questions.length}
                </div>
                <QuestionStatus
                  currentIndex={currentQuestionIndex}
                  answers={answers}
                  questions={questions}
                />
              </>
            )}
          </div>

          {!isCallActive && !hasStartedInterview ? (
            <div className="text-center">
              <div className="mb-8">
                <LightningBoltEmoji />
              </div>
              <h2 className="text-2xl font-bold mb-4">
                Ready to Start Your Interview?
              </h2>
              <p className="mb-8 text-gray-600">
                Click the button below to begin your virtual call
              </p>
              <Button
                onClick={startCall}
                className="w-full bg-[#f8bf5d] hover:bg-[#fba40d] text-black font-bold py-4 px-6 text-xl shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
              >
                <Phone className="mr-2 h-6 w-6" />
                Start Virtual Call
              </Button>
            </div>
          ) : (
            <>
              <div className="bg-black text-white p-4 mb-4">
                <h2 className="text-lg font-bold mb-2">Current Question:</h2>
                <p>{questions[currentQuestionIndex]}</p>
                {isCurrentQuestionAnswered && (
                  <div className="mt-2 flex items-center text-[#f8bf5d]">
                    <Check className="w-4 h-4 mr-2" />
                    <span className="text-sm">Answered</span>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center space-y-4 mb-4">
                <Button
                  onClick={isRecording ? stopRecording : startRecording}
                  disabled={isLoading}
                  className={`w-full px-4 py-2 font-bold ${
                    isRecording
                      ? "bg-red-500 hover:bg-red-600"
                      : "bg-[#f8bf5d] hover:bg-[#fba40d]"
                  } text-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]`}
                >
                  <Mic className="mr-2 h-4 w-4" />
                  {isRecording
                    ? "Stop Recording"
                    : hasRecorded
                    ? "Record Again"
                    : "Record"}
                </Button>
                {hasRecorded && !isRecording && (
                  <Button
                    onClick={confirmAnswer}
                    disabled={isLoading}
                    className="w-full bg-green-500 hover:bg-green-600 text-white font-bold px-4 py-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
                  >
                    {currentQuestionIndex < questions.length - 1 ? (
                      <>
                        Confirm and Next Question
                        <ArrowRight className="ml-2 h-4 w-4" />
                      </>
                    ) : (
                      <>
                        Confirm and End Call
                        <PhoneOff className="ml-2 h-4 w-4" />
                      </>
                    )}
                  </Button>
                )}
              </div>

              {hasRecorded && !isRecording && (
                <p className="text-center text-gray-600 mt-4">
                  Your answer has been recorded. You can re-record if needed, or
                  confirm to move to the next question.
                </p>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SpeechToTextApp;
