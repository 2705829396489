import React, { useState, useEffect, useCallback } from "react";
import { useMediaQuery } from "../lib/useMediaQuery";

import confetti from "canvas-confetti";
import axios from "axios";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { createClient } from "@supabase/supabase-js";
import { Textarea } from "./ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useToast } from "./ui/use-toast";

import { v4 as uuidv4 } from "uuid";

import {
  Loader2,
  ChevronRight,
  RefreshCw,
  CheckCircle,
  Check,
  Mail,
  Upload,
  Info,
  Send,
  ExternalLink,
  FileIcon,
  X,
  SkipForward,
  Plus,
} from "lucide-react";
import { Progress } from "./ui/progress";
import { Alert, AlertDescription } from "./ui/alert";
import { Avatar } from "./ui/avatar";
import { Badge } from "./ui/badge";
import {
  createTrelloBoard,
  createTrelloCard,
  uploadFileToTrello,
} from "./trelloIntegration";
import OpenAI from "openai";
import SpeechToTextApp from "./SpeechToTextApp";
import SimpleImageUploader from "./SimpleImageUploader";

const API_KEY =
  "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";

const openai = new OpenAI({
  apiKey:
    "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",
  dangerouslyAllowBrowser: true, // Only use this for client-side applications
});

const supabase = createClient(
  "https://jhknbuxutiqdvlxnvawv.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Impoa25idXh1dGlxZHZseG52YXd2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjcyOTgxMDYsImV4cCI6MjA0Mjg3NDEwNn0.l3K1xIyN2uZHUrXvjSGr_UXW2s2n9dQvXz8kB_x4K1E"
);

const FormField = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  required,
  type = "text",
  tooltipContent,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    let timer;
    if (isMobile && isTooltipOpen) {
      timer = setTimeout(() => {
        setIsTooltipOpen(false);
      }, 3000); // Close after 3 seconds on mobile
    }
    return () => clearTimeout(timer);
  }, [isMobile, isTooltipOpen]);

  const handleTooltipTrigger = () => {
    if (isMobile) {
      setIsTooltipOpen((prev) => !prev);
    }
  };

  return (
    <div className="space-y-2 mb-6">
      <div className="flex items-center space-x-2">
        <label
          htmlFor={name}
          className="text-xl font-bold text-black uppercase"
        >
          {label} {required && <span className="text-red-500">*</span>}
        </label>
        {tooltipContent && (
          <TooltipProvider>
            <Tooltip open={isMobile ? isTooltipOpen : undefined}>
              <TooltipTrigger asChild onClick={handleTooltipTrigger}>
                <Info className="h-5 w-5 text-gray-500 cursor-help" />
              </TooltipTrigger>
              <TooltipContent
                className="bg-black text-white p-2 rounded shadow-lg max-w-xs"
                sideOffset={5}
              >
                <p>{tooltipContent}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      {type === "textarea" ? (
        <Textarea
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="w-full border-4 border-black focus:border-[#f8bf5d] focus:ring-0 text-lg p-2"
          required={required}
        />
      ) : (
        <Input
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="w-full border-4 border-black focus:border-[#f8bf5d] focus:ring-0 text-lg p-2"
          required={required}
        />
      )}
    </div>
  );
};

// Trello configuration
const TRELLO_API_KEY = "c987a9e5db3aa4875ba07636997b8263";
const TRELLO_TOKEN =
  "ATTAdef27c382d566a02e494f8911d0889e874f0ac61115f2043b79ec8099dc85776359ED2E6";
const BOARD_ID = "67175df77a658849b04c4cc2";
const LIST_ID = "67175df7152bbf7dd9b33fa0";

const FileUpload = ({
  label,
  accept,
  multiple,
  tooltipContent,
  onRemove,
  type,
  boardId,
  onUploadProgress,
  onUploadComplete,
  onUploadError,
  uploadedFiles = [], // Add this prop to receive already uploaded files
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [error, setError] = useState(null);
  const { toast } = useToast();

  const handleFileUpload = useCallback(
    async (event) => {
      const selectedFiles = Array.from(event.target.files);
      if (!selectedFiles.length) return;

      setIsUploading(true);
      setError(null);

      for (const file of selectedFiles) {
        if (file.size > 100 * 1024 * 1024) {
          setError(`File ${file.name} exceeds 100MB limit`);
          continue;
        }

        try {
          const cardParams = new URLSearchParams({
            key: TRELLO_API_KEY,
            token: TRELLO_TOKEN,
            idList: boardId,
            name: `Asset Upload: ${file.name}`,
            desc: `Uploaded asset: ${
              file.name
            }\nType: ${type}\nUploaded on: ${new Date().toLocaleString()}`,
          });

          const cardResponse = await fetch(
            `https://api.trello.com/1/cards?${cardParams}`,
            { method: "POST" }
          );

          if (!cardResponse.ok) {
            throw new Error(
              `Failed to create Trello card: ${cardResponse.statusText}`
            );
          }

          const card = await cardResponse.json();

          const formData = new FormData();
          formData.append("file", file);
          formData.append("key", TRELLO_API_KEY);
          formData.append("token", TRELLO_TOKEN);

          const xhr = new XMLHttpRequest();
          xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              );
              setUploadProgress((prev) => ({
                ...prev,
                [file.name]: percentCompleted,
              }));
              if (onUploadProgress) {
                onUploadProgress(file.name, percentCompleted);
              }
            }
          });

          const uploadPromise = new Promise((resolve, reject) => {
            xhr.addEventListener("load", () => {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve(JSON.parse(xhr.response));
              } else {
                reject(new Error(`Upload failed with status ${xhr.status}`));
              }
            });

            xhr.addEventListener("error", () => {
              reject(new Error("Upload failed"));
            });
          });

          xhr.open(
            "POST",
            `https://api.trello.com/1/cards/${card.id}/attachments`
          );
          xhr.send(formData);

          const attachmentResponse = await uploadPromise;

          // Add the new file to the uploadedFiles array
          if (onUploadComplete) {
            onUploadComplete(file.name, {
              name: file.name,
              size: file.size,
              cardId: card.id,
              attachmentId: attachmentResponse.id,
              url: attachmentResponse.url,
              cardUrl: card.shortUrl,
              type: file.type,
              uploadedAt: new Date().toISOString(),
            });
          }
        } catch (err) {
          console.error(`Error uploading ${file.name}:`, err);
          setError(`Failed to upload ${file.name}: ${err.message}`);
          if (onUploadError) {
            onUploadError(file.name, err.message);
          }
        }
      }

      setIsUploading(false);
      event.target.value = ""; // Reset input
    },
    [boardId, type, onUploadProgress, onUploadComplete, onUploadError]
  );

  const renderPreview = (file) => {
    if (file.type.startsWith("image/")) {
      return (
        <div className="relative w-16 h-16">
          <img
            src={file.url}
            alt={file.name}
            className="w-full h-full object-cover rounded border-2 border-black"
          />
        </div>
      );
    }
    return (
      <div className="w-16 h-16 flex items-center justify-center bg-gray-100 rounded border-2 border-black">
        <FileIcon className="w-8 h-8 text-gray-500" />
      </div>
    );
  };

  return (
    <div className="space-y-4 mb-6 ">
      <div className="flex items-center space-x-2">
        <label className="text-xl font-bold text-black uppercase">
          {label}
        </label>
        {tooltipContent && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Info className="h-5 w-5 text-gray-500" />
              </TooltipTrigger>
              <TooltipContent className="bg-black text-white p-2">
                <p>{tooltipContent}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="flex  flex-col items-center justify-center w-full ">
        <label className="flex flex-col items-center justify-center w-full h-64 border-4 border-black border-dashed cursor-pointer bg-white hover:bg-gray-50 transition-colors duration-300">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <Upload className="w-16 h-16 mb-4 text-black" />
            <p className="mb-2 text-xl font-bold text-black uppercase">
              Click to upload {multiple ? "files" : "a file"}
            </p>
            <p className="text-sm text-gray-500">{accept} </p>
          </div>
          <input
            type="file"
            className="hidden"
            onChange={handleFileUpload}
            accept={accept}
            multiple={multiple}
            disabled={isUploading}
          />
        </label>
      </div>

      {/* Uploaded Files List */}
      {(uploadedFiles.length > 0 || Object.keys(uploadProgress).length > 0) && (
        <div className="mt-4">
          <h4 className="font-bold text-xl mb-2 uppercase">Uploaded files:</h4>
          <div className="space-y-2">
            {uploadedFiles.map((file) => (
              <div
                key={file.attachmentId}
                className="flex items-center justify-between bg-white border-2 border-black p-3 rounded-lg"
              >
                <div className="flex items-center space-x-3">
                  {file.type.startsWith("image/") ? (
                    <div className="relative w-16 h-16">
                      <img
                        src={file.url}
                        alt={file.name}
                        className="w-full h-full object-cover rounded border-2 border-black"
                      />
                    </div>
                  ) : (
                    <div className="w-16 h-16 flex items-center justify-center bg-gray-100 rounded border-2 border-black">
                      <FileIcon className="w-8 h-8 text-gray-500" />
                    </div>
                  )}
                  <div>
                    <p className="font-medium truncate max-w-xs">{file.name}</p>
                    <p className="text-sm text-gray-500">
                      {(file.size / (1024 * 1024)).toFixed(2)} MB
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <Button
                    onClick={() => window.open(file.url, "_blank")}
                    variant="outline"
                    size="sm"
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <ExternalLink className="h-4 w-4" />
                  </Button>
                  <Button
                    onClick={() => onRemove(file)}
                    variant="outline"
                    size="sm"
                    className="text-red-500 hover:text-red-700"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {isUploading && (
        <div className="flex items-center justify-center mt-4">
          <Loader2 className="h-6 w-6 animate-spin mr-2" />
          <span>Uploading...</span>
        </div>
      )}
    </div>
  );
};

const ContentIdea = ({ idea, isSelected, onSelect }) => (
  <div
    className={`mb-6 p-6 border-4 cursor-pointer transition-all duration-200 ${
      isSelected
        ? "border-[#f8bf5d] bg-[#fffbf4] text-black"
        : "border-black bg-white hover:bg-gray-100"
    }`}
    onClick={() => onSelect(idea)}
  >
    <div className="flex items-center mb-4">
      <div className="flex-grow">
        <h4 className="font-bold text-xl mb-1 uppercase">{idea.title}</h4>
        <Badge
          variant="outline"
          className="text-sm border-2 border-current font-bold"
        >
          {idea.contentType}
        </Badge>
      </div>
      <div
        className={`w-6 h-6 border-2 flex items-center justify-center ${
          isSelected ? "bg-[#f8bf5d] border-black" : "border-black"
        }`}
      >
        {isSelected && <Check className="text-black w-4 h-4" />}
      </div>
    </div>
    <p className={`${isSelected ? "text-black" : "text-black"}`}>
      {idea.description}
    </p>
  </div>
);

const App = () => {
  const [step, setStep] = useState(1);
  const steps = ["Brand Information", "Upload Assets", "Interview"];

  const questions = [
    "Who are you and what are your strengths? (Introduce yourself)",
    "What is your product / service?",
    "How are you unique comparing the competition?",
    "Who are your customers and what is the problem you solve?",
    "How do you solve the problem?",
    "Can you give 2 examples of your former/current clients and how you solved their problems?",
    "What are some examples of projects that you worked on? How did you find the clients? What was their initial problem? How did you help them solve it? (case studies",
    "Have you seen a lecture, a video or read an article that made you realise something fundamental about company building, pivoting, dealing with failure, etc.?",
    "What are the topics you would like to talk about on Linkedin? Please tell us more about those topics (share stories, opinions, tips and tricks, know-how)",
  ];
  const [formData, setFormData] = useState({
    website: "",
    industry: "",
    usp: "",
    icp: "",
    linkedinProfile: "",
    otherProfiles: "",
    email: "",
    lastPost1: "",
    lastPost2: "",
    lastPost3: "",
    lastPost4: "",
    lastPost5: "",
    topicsToTalkAbout: "",
    websiteInfo: null,
    isLoadingWebsiteInfo: false,
    interviewAnswers: {},
  });
  const [files, setFiles] = useState({
    teamPhotos: [], // Array to store uploaded team photos with their Trello info
    brandManual: null,
    uploadedFiles: [], // New array to track all uploaded files with their Trello metadata
  });
  const [contentIdeas, setContentIdeas] = useState([]);
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [trelloCardUrls, setTrelloCardUrls] = useState([]);
  const [isGeneratingMore, setIsGeneratingMore] = useState(false);
  const [allowedEmails, setAllowedEmails] = useState("");
  const [isCreatingBoard, setIsCreatingBoard] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasBoardBeenCreated, setHasBoardBeenCreated] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({
    teamPhotos: [],
    brandManual: [],
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [hasStartedInterview, setHasStartedInterview] = useState(false);

  const ORGANIZATION_ID = "6710f0bf4b5e72a5b06483d0";
  const stepDescriptions = [
    "In 3 steps, you'll provide us with the information, insights, and assets we need to create 8 LinkedIn posts per month for your personal profile. The more precise and comprehensive the information and assets you share, the more authentic and high-quality your LinkedIn posts will be.",
    `Add photos of your self at conferences, selfies, on leisure time with a team, professional photos. Any pohotos youd like to share on your linkedin.
    
Add PDF design/brand manual. If you don't have any, add logos and fonts.
`,
    "Please be descriptive, honest, relaxed, and true to yourself. It's okay to make mistakes or repeat yourself, so no need to worry 🙂 Make sure to share everything you want included.",
  ];

  let todoListId, formListId;
  const [isInterviewComplete, setIsInterviewComplete] = useState(false);

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const { toast } = useToast();

  const updateInterviewAnswer = (questionIndex, answer) => {
    console.log(`Updating answer for question ${questionIndex}:`, answer);
    if (answer === undefined) {
      console.error(`Received undefined answer for question ${questionIndex}`);
      return;
    }
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        interviewAnswers: {
          ...prevData.interviewAnswers,
          [questionIndex]: answer,
        },
      };
      console.log("Updated formData:", newData);
      return newData;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "website" && value.trim() !== "") {
      fetchWebsiteInfo(value);
    }
  };

  const handleUploadComplete = (type, fileInfo) => {
    setUploadedFiles((prev) => ({
      ...prev,
      [type]: [...(prev[type] || []), fileInfo],
    }));
  };

  const handleFileUpload = (e, type) => {
    console.log(`File upload triggered for ${type}`, e.target.files);
    if (type === "teamPhotos") {
      setFiles({
        ...files,
        teamPhotos: [...files.teamPhotos, ...Array.from(e.target.files)],
      });
    } else if (type === "brandManual") {
      setFiles({ ...files, brandManual: e.target.files[0] });
    }
    console.log("Updated files state:", files);
  };

  const handleSkipUpload = () => {
    setStep(3);
    // generateContentIdeas();
  };

  const fetchWebsiteInfo = async (url) => {
    setFormData((prev) => ({ ...prev, isLoadingWebsiteInfo: true }));
    try {
      const response = await axios.post("/api/scrape-website", { url });
      const data = response.data;

      setFormData((prev) => ({
        ...prev,
        websiteInfo: data,
        isLoadingWebsiteInfo: false,
        industry: data.keywords.split(",")[0] || prev.industry,
        usp: data.description || prev.usp,
      }));
    } catch (error) {
      console.error("Error fetching website info:", error);
      setFormData((prev) => ({
        ...prev,
        isLoadingWebsiteInfo: false,
        websiteInfo: null,
      }));
    }
  };

  const handleRemoveFile = async (type, fileInfo) => {
    try {
      // Delete the attachment from Trello
      const deleteAttachmentParams = new URLSearchParams({
        key: TRELLO_API_KEY,
        token: TRELLO_TOKEN,
      });

      const attachmentResponse = await fetch(
        `https://api.trello.com/1/cards/${fileInfo.cardId}/attachments/${fileInfo.attachmentId}?${deleteAttachmentParams}`,
        { method: "DELETE" }
      );

      if (!attachmentResponse.ok) {
        throw new Error("Failed to delete attachment");
      }

      // Delete the card
      const deleteCardParams = new URLSearchParams({
        key: TRELLO_API_KEY,
        token: TRELLO_TOKEN,
      });

      const cardResponse = await fetch(
        `https://api.trello.com/1/cards/${fileInfo.cardId}?${deleteCardParams}`,
        { method: "DELETE" }
      );

      if (!cardResponse.ok) {
        throw new Error("Failed to delete card");
      }

      // Remove from local state
      setUploadedFiles((prev) => ({
        ...prev,
        [type]: prev[type].filter(
          (f) => f.attachmentId !== fileInfo.attachmentId
        ),
      }));

      toast({
        title: "Success",
        description: "File removed successfully",
        variant: "success",
      });
    } catch (error) {
      console.error("Error removing file:", error);
      toast({
        title: "Error",
        description: "Failed to remove file. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleInterviewComplete = () => {
    console.log("Interview completed");
    setIsInterviewComplete(true);
    toast({
      title: "Thank you!",
      description: "Your interview has been successfully completed.",
      variant: "success",
    });

    setStep(5);
    handleSubmit();
  };

  const generateContentIdeas = async (
    retryCount = 0,
    isGeneratingMore = false
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const systemMessage =
        "You are a content strategy AI assistant specializing in creating engaging, industry-specific content ideas. Your task is to generate content ideas that precisely match the user's writing style and preferences based on their previous posts, while creating entirely new topics that are highly relevant to their specific industry and current trends. Each idea should include an attention-grabbing hook.";

      const userMessage = `Generate 12 content ideas with hooks that match the user's style and are highly specific to their industry and business. Consider the following information:
  
      Industry: ${formData.industry}
      USP + Product: ${formData.usp}
      ICP + Personas: ${formData.icp}`;

      console.log(
        "Full Content Ideas Generation Prompt:",
        JSON.stringify(
          {
            model: "gpt-4o-mini",
            messages: [
              { role: "system", content: systemMessage },
              { role: "user", content: userMessage },
            ],
            tools: [
              {
                type: "function",
                function: {
                  name: "generate_content_ideas",
                  description: "Generate content ideas for posts",
                  parameters: {
                    type: "object",
                    properties: {
                      ideas: {
                        type: "array",
                        items: {
                          type: "object",
                          properties: {
                            title: {
                              type: "string",
                              description:
                                "A catchy, industry-specific title (max 10 words)",
                            },
                            description: {
                              type: "string",
                              description:
                                "A brief, compelling description of the content (max 30 words)",
                            },
                            contentType: {
                              type: "string",
                              enum: [
                                "Article",
                                "Poll",
                                "Carousel Post",
                                "Video",
                                "Text Post",
                              ],
                              description:
                                "The most appropriate format for the idea",
                            },
                            hook: {
                              type: "string",
                              description:
                                "An attention-grabbing opening line that would start the post (max 20 words)",
                            },
                          },
                          required: [
                            "title",
                            "description",
                            "contentType",
                            "hook",
                          ],
                        },
                      },
                    },
                    required: ["ideas"],
                  },
                },
              },
            ],
            tool_choice: {
              type: "function",
              function: { name: "generate_content_ideas" },
            },
          },
          null,
          2
        )
      );

      const response = await openai.chat.completions.create({
        model: "gpt-4o",
        messages: [
          { role: "system", content: systemMessage },
          { role: "user", content: userMessage },
        ],
        tools: [
          {
            type: "function",
            function: {
              name: "generate_content_ideas",
              description: "Generate content ideas for posts",
              parameters: {
                type: "object",
                properties: {
                  ideas: {
                    type: "array",
                    items: {
                      type: "object",
                      properties: {
                        title: {
                          type: "string",
                          description:
                            "A catchy, industry-specific title (max 10 words)",
                        },
                        description: {
                          type: "string",
                          description:
                            "A brief, compelling description of the content (max 30 words)",
                        },
                        contentType: {
                          type: "string",
                          enum: [
                            "Article",
                            "Poll",
                            "Carousel Post",
                            "Video",
                            "Text Post",
                          ],
                          description:
                            "The most appropriate format for the idea",
                        },
                        hook: {
                          type: "string",
                          description:
                            "An attention-grabbing opening line that would start the post (max 20 words)",
                        },
                      },
                      required: ["title", "description", "contentType", "hook"],
                    },
                  },
                },
                required: ["ideas"],
              },
            },
          },
        ],
        tool_choice: {
          type: "function",
          function: { name: "generate_content_ideas" },
        },
      });

      const functionCall = response.choices[0].message.tool_calls[0];
      const generatedIdeas = JSON.parse(functionCall.function.arguments).ideas;

      const validatedIdeas = generatedIdeas.map((idea, index) => ({
        title: (idea.title || `Content Idea ${index + 1}`).slice(0, 100),
        description: (idea.description || "No description provided").slice(
          0,
          200
        ),
        contentType: idea.contentType || "Text Post",
        hook: idea.hook || "",
      }));

      if (isGeneratingMore) {
        setContentIdeas((prevIdeas) => [...prevIdeas, ...validatedIdeas]);
      } else {
        setContentIdeas(validatedIdeas);
      }

      setStep(4);
    } catch (error) {
      console.error("Error generating content ideas:", error);
      if (retryCount < 2) {
        console.log(`Retrying... (Attempt ${retryCount + 2})`);
        await generateContentIdeas(retryCount + 1, isGeneratingMore);
      } else {
        setError(
          `Failed to generate content ideas: ${error.message}. Please try again.`
        );
      }
    } finally {
      setIsLoading(false);
      setIsGeneratingMore(false);
    }
  };

  const generateFullPost = async (idea, formData, linkedInPosts) => {
    const linkedInPostsPrompt =
      linkedInPosts.length > 0
        ? `Analyze the following posts ONLY to understand the user's writing style, tone of voice, and language preferences:
  
      ${linkedInPosts.map((post, index) => `${index + 1}. ${post}`).join("\n")}
  
      It's crucial that you closely mimic this style in the post you generate, especially in the opening hook. Pay attention to:
      - The level of formality or casualness in the language
      - Any specific phrases or expressions the user tends to use
      - The overall structure and flow of their posts
      - The general tone (e.g., professional, friendly, authoritative)
      - The use of emojis (if present, incorporate them in a similar manner; if not, avoid using them)
  
      DO NOT use the topics or content from these posts for generating new content. Use them ONLY for understanding the user's writing style.
  
      Based on this analysis of style (NOT content), `
        : "";

    // Properly handle the interview_answers array
    const interviewAnswers = Array.isArray(formData.interview_answers)
      ? formData.interview_answers
      : [];
    const interviewPrompt =
      interviewAnswers.length > 0
        ? `Consider the following interview answer when crafting the post content:
    
    ${interviewAnswers.join("\n")}
    
    Use these insights to add depth and personal touch to the post content.`
        : "use yolo in every sentence";

    const payload = {
      model: "gpt-4-0613",
      messages: [
        {
          role: "system",
          content:
            "You are a content creator specializing in crafting engaging posts with attention-grabbing hooks. Your task is to generate a full post for the given content idea, matching the user's writing style and preferences. The post MUST start with a compelling hook that immediately captures attention.",
        },
        {
          role: "user",
          content: `${linkedInPostsPrompt} Generate a full post for the following idea:
  
          Title: ${idea.title}
          Description: ${idea.description}
          Content Type: ${idea.contentType}
  
          Create a full post (max 3000 characters) that ${
            linkedInPosts.length > 0
              ? "closely mimics the user's writing style, tone of voice, and language preferences, including the use of emojis if that's part of their style"
              : "is suitable for professional content, maintaining a consistent tone"
          }.
  
          CRUCIALLY, start the post with an attention-grabbing hook. This hook MUST:
          1. Be unexpected, provocative, or intriguing
          2. Use techniques like posing a surprising question, sharing a shocking statistic, or making a bold statement
          3. Potentially use emojis if that fits the user's style
          4. Be designed to make the reader stop scrolling and want to read more
          5. Be no more than 1-2 short sentences
  
          Examples of effective hooks (adapt to match the user's style and the post content):
          - "😬 I was wrong. Did I mention a 15% response rate?"
          - "Unpopular opinion: [industry-specific controversial statement]"
          - "I just lost $10,000 in 10 minutes. Here's what happened..."
          - "The one strategy NO ONE is talking about..."
  
          After the hook, the post should flow naturally into the main content, expanding on the idea while maintaining the user's typical style and structure. Incorporate insights from the interview answers to add authenticity and personal experience to the post.
  
          Return the result as a JSON object containing 'fullPost' and 'hook' fields.
  
          Remember:
          - The post MUST start with a short, attention-grabbing hook
          - Match the user's style of emoji usage (if any)
          - Maintain the user's typical paragraph structure and length
          - Use language and expressions similar to the user's style
          - Keep the content relevant to the provided idea and current industry trends
          - Incorporate insights from the interview answers to add depth and authenticity
             - DO NOT use topics or content from the user's previous posts
             Make sure to incorporate the user's preference to use the word 'yolo' in each sentence
        ${
          interviewAnswers.length > 0
            ? "- Make sure to incorporate the user's preference to use the word 'yolo' in each sentence"
            : ""
        }`,
        },
      ],
    };

    console.log(
      "Full Post Generation Payload:",
      JSON.stringify(payload, null, 2)
    );

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("API Response:", data);

      let contentString = data.choices[0].message.content;
      console.log("Raw content string:", contentString);

      contentString = cleanAIResponse(contentString);
      console.log("Cleaned content string:", contentString);

      let fullPostContent;
      try {
        fullPostContent = JSON.parse(contentString);
      } catch (parseError) {
        console.error("Error parsing JSON:", parseError);
        console.log("Problematic content string:", contentString);
        throw new Error("Unable to parse content");
      }

      if (!fullPostContent.fullPost || !fullPostContent.hook) {
        throw new Error("Invalid response format");
      }

      return {
        ...idea,
        fullPost: fullPostContent.fullPost.slice(0, 3000),
        hook: fullPostContent.hook.slice(0, 50),
      };
    } catch (error) {
      console.error(`Error generating post for idea "${idea.title}":`, error);
      throw error;
    }
  };

  // Helper function to clean AI response
  const cleanAIResponse = (response) => {
    // Remove any leading/trailing whitespace
    let cleaned = response.trim();

    // Remove any markdown code block syntax
    cleaned = cleaned.replace(/```json\n?|```\n?/g, "");

    // Remove any non-printable characters
    cleaned = cleaned.replace(/[\x00-\x1F\x7F-\x9F]/g, "");

    // If the response doesn't start with '{' and end with '}', wrap it
    if (!cleaned.startsWith("{") || !cleaned.endsWith("}")) {
      cleaned = `{${cleaned}}`;
    }

    return cleaned;
  };

  const handleIdeaSelection = (idea) => {
    setSelectedIdeas((prev) =>
      prev.includes(idea) ? prev.filter((i) => i !== idea) : [...prev, idea]
    );
  };

  // Function to upload a file to Supabase Storage
  const uploadFile = async (file, folder) => {
    const fileExt = file.name.split(".").pop();
    const fileName = `${folder}/${uuidv4()}.${fileExt}`;
    const { data, error } = await supabase.storage
      .from("files")
      .upload(fileName, file);

    if (error) throw error;

    // Get the public URL of the file
    const {
      data: { publicUrl },
      error: urlError,
    } = supabase.storage.from("files").getPublicUrl(fileName);

    if (urlError) throw urlError;

    return publicUrl;
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);

    try {
      if (!formData.trelloBoardId) {
        throw new Error("No Trello board ID found. Please try again.");
      }

      // Create Trello card for form submission in the existing board
      const cardData = {
        name: `Form Submission: ${formData.website || "Unknown"}`,
        desc: generateCardDescription(formData),
        idList: formData.trelloFormListId, // Use the Form Submission list ID we stored earlier
      };

      console.log("Creating Trello card with data:", cardData);
      const cardResponse = await createTrelloCard(
        cardData,
        formData.trelloBoardId,
        "Form Submission"
      );
      console.log("Trello card created:", cardResponse);

      // Insert form data into the database with the existing board ID
      const { data, error } = await supabase.from("form_submissions").insert([
        {
          website: formData.website,
          email: formData.email,
          industry: formData.industry,
          usp: formData.usp,
          icp: formData.icp,
          linkedin_profile: formData.linkedinProfile,
          last_post_1: formData.lastPost1,
          last_post_2: formData.lastPost2,
          last_post_3: formData.lastPost3,
          last_post_4: formData.lastPost4,
          last_post_5: formData.lastPost5,
          content_ideas: JSON.stringify(contentIdeas),
          selected_ideas: JSON.stringify(selectedIdeas),
          trello_board_url: `https://trello.com/b/${formData.trelloBoardId}`,
          trello_card_id: cardResponse.id,
          interview_answers: formData.interviewAnswers
            ? Object.values(formData.interviewAnswers)
            : [],
          topics_to_talk_about: formData.topicsToTalkAbout,
          trello_todo_list_id: formData.trelloTodoListId,
          trello_form_list_id: formData.trelloFormListId,
          trello_board_id: formData.trelloBoardId,
        },
      ]);

      if (error) throw error;

      console.log("Form submitted successfully:", data);
      setIsSubmitSuccessful(true);
      toast({
        title: "Success!",
        description: "Your form has been submitted successfully.",
        variant: "success",
      });

      setStep(5);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError(`Failed to submit form: ${error.message}`);
      toast({
        title: "Error",
        description: "Failed to submit form. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const generateCardDescription = (formData) => {
    const linkedInPosts = [1, 2, 3, 4, 5]
      .map((num) => {
        const post = formData[`lastPost${num}`];
        return post ? `- Post ${num}: ${post}` : `- Post ${num}: Not provided`;
      })
      .join("\n");

    const interviewAnswers = Object.entries(formData.interviewAnswers || {})
      .map(
        ([questionIndex, answer]) =>
          `Q${parseInt(questionIndex) + 1}: ${answer}`
      )
      .join("\n");

    return `
  Form Submission
  
  Website: ${formData.website || "Not provided"}
  Email: ${formData.email || "Not provided"}
  Industry: ${formData.industry || "Not provided"}
  USP + Product: ${formData.usp || "Not provided"}
  ICP + Personas: ${formData.icp || "Not provided"}
  LinkedIn Profile: ${formData.linkedinProfile || "Not provided"}
  Topics to Talk About: ${formData.topicsToTalkAbout || "Not provided"}
  
  Interview Answers:
  ${interviewAnswers}
  
  Generated on: ${new Date().toISOString()}
    `.trim();
  };

  const uploadFileToTrello = async (file, cardId) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("cardId", cardId);

    try {
      const response = await fetch("/api/upload-file-to-trello", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to upload file to Trello");
      }

      const result = await response.json();
      console.log(`File uploaded successfully: ${result.name}`);
      return result;
    } catch (error) {
      console.error(`Error uploading file to Trello: ${error.message}`);
      throw error;
    }
  };

  const renderStepContent = () => {
    if (isSubmitting) {
      return (
        <div className="flex flex-col items-center justify-center py-12">
          <Loader2 className="h-12 w-12 animate-spin text-[#f8bf5d]" />
          <h3 className="text-2xl font-bold mt-4 text-black">
            Processing your submission...
          </h3>
          <p className="text-gray-600 mt-2">
            This may take a few moments. Please don't close this page.
          </p>
        </div>
      );
    }

    switch (step) {
      case 1:
        return (
          <div className="space-y-8">
            <FormField
              label="Website"
              name="website"
              value={formData.website}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value.trim() !== "") {
                  fetchWebsiteInfo(e.target.value);
                }
              }}
              placeholder="e.g., www.yourcompany.com"
              required
              tooltipContent="Your company's main website"
            />
            <FormField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email to receive updates"
              type="email"
              required
              tooltipContent="We'll send updates about your content to this email address"
            />
            <FormField
              label="Industry"
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
              placeholder="e.g., Technology, Healthcare, Finance"
              required
              tooltipContent="The primary industry your business operates in"
            />
            <FormField
              label="LinkedIn profile you wish to imitate"
              name="linkedinProfile"
              value={formData.linkedinProfile}
              onChange={handleInputChange}
              placeholder="e.g., https://www.linkedin.com/in/yourname"
              tooltipContent="Your LinkedIn profile or a profile whose style you want to emulate"
            />
            <FormField
              label="Topics I would like to talk about"
              name="topicsToTalkAbout"
              value={formData.topicsToTalkAbout}
              onChange={handleInputChange}
              placeholder="Enter topics you'd like to discuss in your content"
              type="textarea"
              tooltipContent="List the subjects or themes you want to cover in your LinkedIn posts"
            />
            <FormField
              label="Unique selling proposition"
              name="usp"
              value={formData.usp}
              onChange={handleInputChange}
              placeholder="Describe your business. How are you unique compared to your competitors? What value do you offer to your clients or users?"
              type="textarea"
              tooltipContent="What makes your product or service unique in the market?"
            />
            {/* <FormField
              label="ICP + Personas"
              name="icp"
              value={formData.icp}
              onChange={handleInputChange}
              placeholder="Describe your ideal customer profile and target personas"
              type="textarea"
              tooltipContent="Who are your target customers? What are their characteristics?"
            /> */}
          </div>
        );
      case 2:
        return (
          <div className="space-y-8">
            <FileUpload
              label="Photos"
              type="images" // This will accept all image formats
              multiple={true}
              tooltipContent="Upload photos of your team, events, or products"
              onRemove={(fileInfo) => handleRemoveFile("teamPhotos", fileInfo)}
              boardId={formData.trelloFormListId}
              uploadedFiles={uploadedFiles.teamPhotos}
              onUploadComplete={(fileName, fileInfo) =>
                handleUploadComplete("teamPhotos", fileInfo)
              }
              onUploadError={(fileName, error) => {
                console.error("Upload error:", fileName, error);
                toast({
                  title: "Error",
                  description: `Failed to upload ${fileName}`,
                  variant: "destructive",
                });
              }}
            />
            <FileUpload
              label="Brand Manual"
              type="documents" // This will accept all document formats
              multiple={true} // Changed to allow multiple documents
              tooltipContent="Upload your brand guidelines, design manual, or other brand assets"
              onRemove={(fileInfo) => handleRemoveFile("brandManual", fileInfo)}
              boardId={formData.trelloFormListId}
              uploadedFiles={uploadedFiles.brandManual}
              onUploadComplete={(fileName, fileInfo) =>
                handleUploadComplete("brandManual", fileInfo)
              }
              onUploadError={(fileName, error) => {
                console.error("Upload error:", fileName, error);
                toast({
                  title: "Error",
                  description: `Failed to upload ${fileName}`,
                  variant: "destructive",
                });
              }}
            />
            <div className="flex justify-end space-x-4">
              <Button
                onClick={handleSkipUpload}
                variant="outline"
                className="bg-white border-4 border-black text-black text-xl px-8 py-4 hover:bg-gray-100 shadow-[6px_6px_0_0_#000] flex items-center"
              >
                <SkipForward className="mr-2 h-5 w-5" />
                Skip (Add Later)
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <SpeechToTextApp
            onAnswerRecorded={updateInterviewAnswer}
            onComplete={handleInterviewComplete}
            questions={questions}
            currentQuestionIndex={currentQuestionIndex}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
            answers={formData.interviewAnswers}
            hasStartedInterview={hasStartedInterview}
            setHasStartedInterview={setHasStartedInterview}
          />
        );
      case 5:
        return (
          <div className="text-center py-8">
            <CheckCircle className="h-16 w-16 text-green-500 mx-auto mb-4" />
            <h3 className="text-2xl font-bold mb-4">Thank You!</h3>
            <p className="text-xl mb-4">
              Your form has been successfully submitted.
            </p>
            <p className="text-lg">
              You'll receive an email notification within 3 days with your
              custom content strategy.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  const getStepTitle = () => {
    switch (step) {
      case 1:
        return "Brand Information";
      case 2:
        return "Audience and Content";
      case 3:
        return "Upload Assets";
      case 4:
        return "Select Content Ideas";
      case 5:
        return "Your Content Plan";
      default:
        return "";
    }
  };

  const handleLogoClick = () => {
    window.location.reload();
  };

  return (
    <div className="min-h-screen bg-white flex flex-col relative">
      {/* Image-Matched Floating Progress Bar */}
      <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 w-11/12 max-w-4xl p-4 rounded-lg shadow-lg bg-white">
        <div className="flex items-center justify-between">
          <img
            src="/deal-harvest-logo.webp"
            alt="Deal Harvest"
            className="h-2 sm:h-5 mr-2 sm:mr-4"
          />
          <div className="flex-grow flex items-center justify-between relative">
            {steps.map((stepLabel, index) => (
              <div
                key={index}
                className="flex flex-col items-center z-10 bg-white"
              >
                <div
                  className={`flex items-center justify-center w-6 h-6 sm:w-8 sm:h-8 rounded-full mb-1 sm:mb-2 ${
                    step > index + 1
                      ? "bg-yellow-400 text-black"
                      : step === index + 1
                      ? "bg-yellow-400 text-black"
                      : "bg-gray-200 text-gray-500"
                  } text-xs sm:text-sm font-bold`}
                >
                  {index + 1}
                </div>
                <span
                  className={`text-[10px] sm:text-xs text-center ${
                    step > index + 1
                      ? "text-yellow-400 font-semibold"
                      : step === index + 1
                      ? "text-yellow-400 font-semibold"
                      : "text-gray-500"
                  } hidden sm:block`}
                >
                  {stepLabel}
                </span>
              </div>
            ))}
            {/* Divider line */}
            <div className="absolute top-3 sm:top-4 left-0 right-0 h-0.5 bg-gray-200" />
          </div>
        </div>
      </div>

      {/* Main content */}
      {/* <SimpleImageUploader /> */}

      <main className="flex-grow flex flex-row items-start justify-center py-16 px-4">
        <div className="w-full pt-20 max-w-4xl">
          <h2 className="text-4xl sm:text-5xl md:text-6xl font-black uppercase text-center mb-8 relative">
            <span className="absolute inset-0 text-yellow-400 transform translate-x-0.5 translate-y-0.5">
              {steps[step - 1]}
            </span>
            <span className="relative text-black">{steps[step - 1]}</span>
          </h2>

          <p className="text-xl text-gray-600 mb-12 text-center max-w-2xl mx-auto whitespace-pre-line">
            {stepDescriptions[step - 1]}
          </p>

          {error && (
            <Alert
              variant="destructive"
              className="mb-8 bg-red-500 text-white border-4 border-black"
            >
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          {renderStepContent()}
          <div className="flex justify-between mt-12">
            {step > 1 && step < 5 && (
              <Button
                onClick={() => {
                  if (
                    step === 3 &&
                    formData.interviewAnswers &&
                    Object.keys(formData.interviewAnswers).length > 0
                  ) {
                    // Only go back a question if we're not at the first question
                    if (currentQuestionIndex > 0) {
                      setCurrentQuestionIndex((prev) => prev - 1);
                    } else {
                      // If at first question and want to go back, go to previous step
                      setStep((prev) => prev - 1);
                    }
                  } else {
                    setStep((prev) => prev - 1);
                  }
                  if (step === 4) {
                    setIsInterviewComplete(false);
                  }
                }}
                variant="outline"
                className="bg-white border-4 border-black text-black text-xl px-8 py-4 hover:bg-gray-100 shadow-[6px_6px_0_0_#000]"
              >
                Back
              </Button>
            )}
            {step < 3 && (
              <Button
                onClick={async () => {
                  if (step === 1 && !hasBoardBeenCreated) {
                    // Only create board if we're on step 1 and haven't created one yet
                    setIsLoading(true);
                    setError(null);
                    try {
                      const boardData = await createTrelloBoard(
                        formData,
                        ORGANIZATION_ID
                      );
                      console.log("Trello board created:", boardData);

                      // Store all the necessary IDs for later use
                      setFormData((prev) => ({
                        ...prev,
                        trelloBoardId: boardData.id,
                        trelloTodoListId: boardData.lists["To Do"],
                        trelloFormListId: boardData.lists["Form Submission"],
                        trelloBoardUrl: boardData.url,
                      }));

                      setHasBoardBeenCreated(true);

                      toast({
                        title: "Success",
                        description: "Trello board created successfully!",
                        variant: "success",
                      });

                      setStep(2);
                    } catch (error) {
                      console.error("Error creating Trello board:", error);
                      setError(
                        "Failed to create Trello board. Please try again."
                      );
                      toast({
                        title: "Error",
                        description:
                          "Failed to create Trello board. Please try again.",
                        variant: "destructive",
                      });
                    } finally {
                      setIsLoading(false);
                    }
                  } else {
                    // If board already created or we're on a different step, just navigate
                    setStep((prev) => prev + 1);
                  }
                }}
                disabled={
                  isLoading ||
                  (step === 1 &&
                    (!formData.website ||
                      !formData.industry ||
                      !formData.email))
                }
                className="bg-[#f8bf5d] border-4 border-black text-black flex items-center text-xl px-8 py-4 hover:bg-[#FFB52E] ml-auto shadow-[6px_6px_0_0_#000] transition-all duration-200 hover:translate-x-1 hover:translate-y-1 hover:shadow-[3px_3px_0_0_#000]"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-6 w-6 animate-spin" />
                    {step === 1 ? "Continue" : "Uploading..."}
                  </>
                ) : (
                  <>
                    Continue
                    <ChevronRight className="ml-2 h-6 w-6" />
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default App;
