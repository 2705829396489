const QuestionStatus = ({ currentIndex, answers, questions }) => {
  return (
    <div className="flex gap-2 justify-center mb-4">
      {questions.map((_, index) => {
        const isAnswered = answers[index];
        const isCurrent = currentIndex === index;

        return (
          <div
            key={index}
            className={`w-3 h-3 rounded-full transition-all duration-200 ${
              isAnswered
                ? "bg-green-500"
                : isCurrent
                ? "bg-[#f8bf5d]"
                : "bg-gray-300"
            } ${isCurrent ? "ring-2 ring-black ring-offset-2" : ""}`}
            title={`Question ${index + 1}${isAnswered ? " - Answered" : ""}`}
          />
        );
      })}
    </div>
  );
};

export default QuestionStatus;
