import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainContent from "./components/MainContent";
import AdminPage from "./components/AdminPage";

const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-white flex flex-col relative">
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
